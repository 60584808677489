<template>
  <div id="app">
    <transition name="fade">
      <HomePage
        class="page"
        v-if="currentPage === 'home'"
        @toggleDebug="setDebug(true)"
      />
      <ConnectingPage class="page" v-if="currentPage === 'connecting'" />
      <NameInputPage v-if="currentPage === 'auth'" class="page" />
      <LobbyPage class="page" :game="game" v-if="currentPage === 'lobby'" />
      <ErrorPage class="page" :message="error" v-if="currentPage === 'error'" />
      <LoginPage class="page" v-if="currentPage === 'login'" />
      <ProfilePage
        class="page"
        :profileData="profileData"
        v-if="currentPage === 'profile'"
      />
      <PickingLetterPage
        ref="pickingLetter"
        class="page"
        :game="game"
        v-if="currentPage === 'picking-letter'"
      />
      <GamePage class="page" :game="game" v-if="currentPage === 'game'" />
      <VotePage class="page" :game="game" v-if="currentPage === 'vote'" />
      <ResultPage class="page" :game="game" v-if="currentPage === 'result'" />
      <ChangePasswordPage
        class="page"
        v-if="currentPage === 'change-pass'"
        :secret="passwordSecret"
      />
    </transition>
    <Adsense
      v-if="showAds"
      data-ad-client="ca-pub-8143494181362005"
      data-ad-slot="1414717897"
    >
    </Adsense>
  </div>
</template>

<script>
import HomePage from "./views/HomePage";
import LobbyPage from "./views/LobbyPage";
import ConnectingPage from "./views/ConnectingPage";
import NameInputPage from "./views/NameInputPage";
import ErrorPage from "./views/ErrorPage";
import PickingLetterPage from "./views/PickingLetterPage";
import GamePage from "./views/GamePage";
import VotePage from "./views/VotePage";
import ResultPage from "./views/ResultPage";
import LoginPage from "./views/LoginPage";
import ProfilePage from "./views/ProfilePage";
import Game from "./client/game";
import i18n from "./plugins/i18n";
import Client from "./client/client";
import ChangePasswordPage from "./views/ChangePasswordPage.vue";
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import '@beyonk/gdpr-cookie-consent-banner/dist/style.css'
const { attachBanner } = require('@beyonk/gdpr-cookie-consent-banner/dist/esm/bundle.js')

export default {
  name: "App",
  components: {
    HomePage,
    LobbyPage,
    ConnectingPage,
    NameInputPage,
    ErrorPage,
    PickingLetterPage,
    GamePage,
    VotePage,
    ResultPage,
    LoginPage,
    ProfilePage,
    ChangePasswordPage,
  },
  data() {
    return {
      game: undefined,
      joinInfo: undefined,
      showUserProfile: false,
      showPasswordReset: false,
      passwordSecret: undefined,
      auth: "no",
      error: undefined,
      session: undefined,
      profileData: {},
      debug: false,
      mobile: false,
      alpha: true,
    };
  },
  computed: {
    showAds() {
      return window.location.host !== "localhost";
    },
    currentPage() {
      if (this.error) {
        return "error";
      }
      if (this.showPasswordReset) {
        return "change-pass";
      }
      if (this.showUserProfile) {
        if (this.session === undefined) {
          return "login";
        } else {
          if (!this.profileData["username"]) {
            Client.requestProfileData();
          }
          return "profile";
        }
      }
      if (this.game !== undefined) {
        return this.game.details.state;
      } else {
        if (this.auth !== "no") {
          return "auth";
        } else {
          return "home";
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      SplashScreen.hide();
      console.log("ATTCH BANNER", attachBanner);
    }, 500);
    if (Capacitor.getPlatform() !== "web") {
      window.setMobile();
    }
  },
  created() {
    Client.app = this;
    window.app = this;
    this.setDebug(true); //rm
    setTimeout(() => {
      import('@beyonk/gdpr-cookie-consent-banner').then(mod => {
        console.log(mod.default);
      });
    }, 2000);
    window.setMobile = () => {
      console.log("Switching to mobile mode...");
      this.mobile = true;
      window.mobile = true;
    };
    i18n.locale = this.getBrowserLanguage();
    document.title = i18n.t("title_long");
    if (localStorage.getItem("sid") && localStorage.getItem("uid")) {
      Client.setSession(
        localStorage.getItem("sid"),
        localStorage.getItem("uid")
      );
    }
    if (this.session !== undefined && !this.profileData["username"]) {
      Client.requestProfileData();
    }
    if (window.location.hash && window.location.hash.length > 1) {
      const hashArgs = window.location.hash.substr(1).split(":");
      switch (hashArgs[0]) {
        case "activate":
          this.showUserProfile = true;
          try {
            Client.confirmRegistration(hashArgs[1], hashArgs[2]).then(
              (response) => {
                if (response.success) {
                  this.$alert(
                    "Dein Account wurde aktiviert! Du kannst dich nun einloggen.",
                    "Erfolg",
                    "success"
                  );
                } else {
                  console.error(response);
                  this.$alert(
                    response.error[1] + " (" + response.error[0] + ")",
                    "Fehler",
                    "error"
                  );
                }
                window.location.hash = "";
              }
            );
          } catch (e) {
            console.error(e);
          }
          console.log("Sent");
          break;
        case "rp":
          this.showPasswordReset = true;
          this.passwordSecret = hashArgs[1];
          break;
        default:
          const gameKey = window.location.hash.substr(1);
          const args = gameKey.split("_");
          if (localStorage.getItem("last_auth")) {
            const lastAuth = JSON.parse(localStorage.getItem("last_auth"));
            if ("" + lastAuth.server === args[0] && lastAuth.game === args[1]) {
              this.connectToGame(
                lastAuth.server,
                lastAuth.game,
                "",
                true,
                lastAuth.player
              );
            } else {
              this.join(args[0], args[1]);
              this.auth = "join";
            }
          } else {
            this.join(args[0], args[1]);
            this.auth = "join";
          }
      }
    }
  },
  methods: {
    setDebug(state) {
      this.debug = state;
    },
    getBrowserLanguage() {
      if (window.location.host.includes("categories.cool") && Capacitor.getPlatform() === 'web') {
        return "en";
      }
      switch (navigator.language.substr(0, 2)) {
        case "de":
          return "de";
        default:
          return "en";
      }
    },
    join(server, game) {
      console.log("Joining", server, game);
      this.joinInfo = { server, game };
      this.auth = "join";
      /**
      if (Client.session) {
        console.log("Session defined");
        if (!this.profileData.name) {
          console.log("Awaiting load");
          Client.profileLoading.then(() => {
            console.log("Done");
            this.setAuthName(this.profileData.name);
          });
        } else {
          console.log("Profile found");
          this.setAuthName(this.profileData.name);
        }
      } else {
        console.log("No session");
        console.log("Client session undef", Client.session);
        this.auth = "join";
      }
      */
    },
    setAuthName(name) {
      if (this.auth === "join") {
        console.log("Joining", this.joinInfo, name);
        this.connectToGame(this.joinInfo.server, this.joinInfo.game, name);
        this.joinInfo = undefined;
      } else if (this.auth === "create") {
        console.log("post create");
        Client.post(Client.centralServer() + "/create", {})
          .then((response) => {
            console.log("got. connecting");
            this.connectToGame(response.server, response.game, name);
          })
          .catch((error) => {
            console.error(error);
            this.$alert(i18n.t("server_offline"), i18n.t("error"), "error");
          });
      }
      this.auth = "no";
    },
    connectToGame(server, game, name, reconnect = false, lastPlayerId = -1) {
      console.log("Connecting...", Client.session,  server, game, reconnect, lastPlayerId);
      if (lastPlayerId) {
        console.log("Connecting...", server, game, reconnect, lastPlayerId);
        this.game = new Game(
          server,
          game,
          name,
          {
            image: this.profileData.image,
            points: this.profileData.points,
          },
          this,
          reconnect,
          lastPlayerId
        );
      } else {
        this.game = new Game(server, game, name, {}, this, reconnect);
      }
      window.location.hash = server + "_" + game;
    },
    reloadPage() {
      window.location.hash = "";
      window.location.reload();
    },
    exitGame() {
      this.error = undefined;
      this.auth = "no";
      if (this.game !== undefined) {
        this.game.exit();
        this.game = undefined;
        location.hash = "";
      } else {
        console.warn("Game not defined.");
      }
    },
    onRoundChanged() {
      if (this.currentPage === "picking-letter") {
        this.$refs.pickingLetter.setLetter(this.game.round.letter);
      }
    },
    showError(error) {
      this.error = error;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#app {
  overflow-x: hidden;
  overflow: hidden;
}
.center-content {
  max-width: 800px;
  margin-left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 20px black;
  margin-top: 2em;
  margin-bottom: 2em;
  border-radius: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
}

.bg {
  background-position: center;
  background-size: cover;
}

.page {
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 90px;
}

.bg-0 {
  background-image: url("./assets/img/back/back.jpg");
}
.bg-1 {
  background-image: url("./assets/img/back/back1.jpg");
  background-position: right center !important;
}
.bg-2 {
  background-image: url("./assets/img/back/back2.jpg");
}
.bg-3 {
  background-image: url("./assets/img/back/back3.jpg");
}
.bg-4 {
  background-image: url("./assets/img/back/back4.jpg");
  background-position: right center !important;
}

.hcenter {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.center-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1em;
  border-radius: 1em;
  transition: 300ms;
  box-shadow: 0em 0em 2em black;
}

@media (min-width: 670px) {
  .main-panel {
    min-width: 400px !important;
    margin-left: 1em;
  }
  .side-panel {
    max-width: 400px !important;
    margin-right: 1em;
    align-self: flex-start;
    position: sticky;
    top: 0;
  }
}
@media (max-width: 670px) {
  .main-panel {
    min-width: 100vw !important;
    margin-right: 0;
  }
  .side-panel {
    min-width: 100vw !important;
    max-width: unset;
    margin-right: 0;
    margin-top: 1em;
  }
}

.clickable {
  cursor: pointer;
}
</style>
