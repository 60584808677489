
const io = require('socket.io-client');
const alert = require("vue-simple-alert");
const i18n = require('../plugins/i18n');
const Client = require('./client');

class Game {
    constructor(server, id, name, userInfo, app, reconnect, initPlayerId = -1) {
        this.server = server;
        this.app = app;
        this.id = id;
        this.playerId = initPlayerId;
        this.originalName = name;
        this.userInfo = userInfo;
        this.playerProfile = {};
        this.ping = 999;
        this.chat = [];
        this.httpUrl = 'https://' + (window.location.host === 'localhost' && !window.mobile && localStorage.getItem('forcePublicServer') !== 'true' ? 'slf' : 's') + server + '.' + this.getDomain();
        this.wordInput = {};
        this.roundResults = {};
        this.details = {
            state: 'connecting'
        };
        this.settings = {
            words: []
        };
        this.round = {};
        this.playerList = [];
        console.log("HTTPURL " + this.httpUrl);
        this.socket = io(this.httpUrl, {
            reconnectionDelayMax: 10000,
        });
        console.log("Socket created");
    
        this.socket.on('connect', data => {
            console.log("socket.on connect", data);
            if(this.playerId <= -1) {
                if(this.app.debug) console.log("DEBUG: First connect")
                this.socket.emit('join', { id: this.id, name, userInfo: this.userInfo, reconnect });
            } else {
                if(this.app.debug) console.log("DEBUG Reconnect");
                this.socket.emit('join', {id: this.id, userInfo: this.userInfo, playerId: this.playerId, name: this.playerProfile.name ? this.playerProfile.name : this.originalName, reconnect: true});
                window.dispatchEvent(new CustomEvent('chat', { detail: {type: 'system', content: 'reconnecting'} }));
            }
        });
        //this.socket.on('reconnect', data => {
        //    if(this.app.debug) window.alert("Trigger reconnect")
        //    this.socket.emit('join', {id: this.id, name: this.playerProfile.name ? this.playerProfile.name : this.originalName, reconnect: true});
        //    window.dispatchEvent(new CustomEvent('chat', { detail: {type: 'system', content: 'reconnecting'} }));
        //});
        this.socket.once('player_id', data => {
            console.log("socket.once player_id", data);
            this.playerId = data.id;
            localStorage.setItem('last_auth', JSON.stringify(
                {
                    server: this.server,
                    game: this.id,
                    player: this.playerId
                }
            ));
        });
        this.socket.on('error', data => {
            console.log("socket.on error", data);
            this.app.showError(data.msg);
            this.processAny(data);
        });
        this.socket.on('playerlist', data => {
            console.log("socket.on playerlist", data);
            this.playerList = data.list;
            this.playerProfile = data.list[this.playerId];
            this.processAny(data);
        });
        this.socket.on('details', data => {
            this.details = data;
            this.processAny(data);
        });
        this.socket.on('chat', data => {
            this.dispatchChat(data);
            this.processAny(data);
        });
        this.socket.on('settings', data => {
            this.settings = data.settings;
            this.processAny(data);
        });
        this.socket.on('round', data => {
            this.round = data.round;
            this.app.onRoundChanged();
            this.processAny(data);
        });
        this.socket.on('results', data => {
            this.doResultAction(data);
            this.processAny(data);
        });
        this.socket.on('close', data => {
            this.processClose(data.reason);
            this.processAny(data);
        });
    }

    /**getDomain() {
        if (window.location.host.indexOf('.') !== window.location.host.lastIndexOf('.')) {
            return window.location.host.substr(window.location.host.indexOf('.') + 1);
        } else {
            return window.location.host;
        }
    }*/

    getDomain() {
        if(window.location.host.includes('localhost') && !window.app.mobile && localStorage.getItem('forcePublicServer') !== 'true') {
            console.log("Running on Localhost.");
            return 'localhost';
        } else {
            console.log("Running on public.");
            return 'stadtlandfluss.cool';
        }
    }

    dispatchChat(data) {
        this.chat.push(data);
        window.dispatchEvent(new CustomEvent('chat', { detail: data }));
    }
    processAny(data) {
        if (data.ts) {
            this.ping = (Date.now() - data.ts) * 2;
        }
    }
    exit() {
        this.socket.close();
    }

    send(action, data) {
        this.socket.emit(action, data);
    }

    sendChat(message) {
        this.send('chat', { message });
    }

    onlinePlayerCount() {
        let count = 0;
        for (const player in this.playerList) {
            if (this.playerList[player].conn === 'on') {
                count++;
            }
        }
        return count;
    }
    setPlayerName(name) {
        this.send('profile', { attribute: 'name', value: name });
    }

    updateAvatar(avatar) {
        this.send('avatar', {avatar});
    }

    kickPlayer(playerId) {
        this.send('playerlist_action', { action: 'kick', player: playerId });
    }

    makeAdmin(playerId) {
        this.send('playerlist_action', { action: 'make_admin', player: playerId });
    }

    addWord(word) {
        this.send('settings', { action: 'add_word', word });
        if (this.settings.words.length < this.settings.maxWords) {
            this.settings.words.push(word);
        }
    }

    removeWord(word) {
        this.send('settings', { action: 'remove_word', word });
        this.settings.words.splice(this.settings.words.indexOf(word), 1);
    }

    setSetting(key, value) {
        this.send('settings', { action: 'set', key, value });
        this.settings[key] = value;
    }

    start() {
        this.send('game', { action: 'start' });
    }

    updateProgress(progress, thinking) {
        this.send('round', { action: 'progress', progress: { progress, thinking } });
    }

    markDone() {
        this.round.stopped.push(this.playerProfile.id);
        this.send('round', { action: 'stop' });
    }

    doResultAction(data) {
        switch (data.action) {
            case 'request':
                if (data.origin) {
                    alert.VueSimpleAlert.alert(this.playerList[data.origin].name + ' ' + i18n.default.t('player_is_done'), i18n.default.t('round_ended'), 'info');
                } else {
                    alert.VueSimpleAlert.alert(i18n.default.t('time_is_up'), i18n.default.t('round_ended'), 'info');
                }
                this.send('round', { action: 'results', results: this.wordInput });
                break;
            case 'receive':
                this.roundResults = data.results;
                this.round = data.round;
                break;
        }
    }

    likeAnswer(playerId, word) {
        console.log("Liking", word, "by", playerId)
        if (this.roundResults.answers[playerId][word].like.includes(this.playerProfile.id)) {
            this.roundResults.answers[playerId][word].like.splice(this.roundResults.answers[playerId][word].like.indexOf(this.playerProfile.id), 1);
            this.roundResults.likes[playerId]--;
        } else {
            this.roundResults.answers[playerId][word].like.push(this.playerProfile.id);
            this.roundResults.answers[playerId][word].like = [...this.roundResults.answers[playerId][word].like];
            if (this.roundResults.likes[playerId] !== undefined) {
                this.roundResults.likes[playerId]++;
            } else {
                this.roundResults.likes[playerId] = 1;
            }
            this.roundResults.likes = { ...this.roundResults.likes };
            console.log("TOtal likes", this.roundResults.likes);
            console.log("Answer Likes", this.roundResults.answers[playerId][word].like)
        }
        this.send('round', { action: 'like', player: playerId, word });
    }

    rejectAnswer(playerId, word) {
        if (this.roundResults.answers[playerId][word].reject.includes(this.playerProfile.id)) {
            this.roundResults.answers[playerId][word].reject.splice(this.roundResults.answers[playerId][word].reject.indexOf(this.playerProfile.id), 1);
        } else {
            this.roundResults.answers[playerId][word].reject.push(this.playerProfile.id);
        }
        this.send('round', { action: 'reject', player: playerId, word });
    }

    requestNewLetter() {
        this.send('round', { action: 'new-letter' });
    }

    markVoteDone() {
        this.send('round', { action: 'vote-done' });
        this.roundResults.done.push(this.playerProfile.id);
    }

    forceVoteDone() {
        this.send('round', { action: 'force-vote-done' });
    }

    restartRound() {
        this.send('round', {action: 'restart'});
    }

    finishResults() {
        this.send('game', { action: 'result-done' });
    }

    processClose(reason) {
        switch(reason) {
            case 'kick':
                alert.VueSimpleAlert.alert(i18n.default.t('kicked_message'), i18n.default.t('connection_closed'), 'error');
                break;
            default:
                alert.VueSimpleAlert.alert(i18n.default.t('closed_message'), i18n.default.t('connection_closed'), 'error');
                break;
        }
        this.app.exitGame();
    }

    sendFeedback(message, mail) {
        this.send('feedback', {message, mail});
    }

    setAvatar(avatarId) {
        this.send('profile', {attribute: 'avatar', value: avatarId});
    }

    assignRandomTeams() {
        this.send('game', {action: 'assign-random-teams'});
    }

    addTeam(title) {
        this.send('game', {action: 'add-team', title});
    }

    removeTeam(index) {
        this.send('game', {action: 'remove-team', index});
    }

    selectTeam(player, team) {
        this.send('game', {action: 'select-team', team, player});
    }
}

module.exports = Game;