const Client = {
    INSTANCE_COUNT: 1,
    session: undefined,
    userid: undefined,
    app: undefined,
    profileLoading: undefined,
    centralServer() {
        return 'server'
    },
    post(url, request, noBase = false) {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.addEventListener('load', data => {
                try {
                    resolve(JSON.parse(req.responseText));
                } catch (e) {
                    reject(req.responseText);
                }
            });
            req.addEventListener('error', data => {
                reject(req.responseText);
            });
            if(noBase) {
                console.log("Post url", url);
                req.open('POST', url);
            } else {
                console.log("Post url", this.getBase() + url);
                req.open('POST', this.getBase() + url);                
            }
            if (request) {
                request.lang = navigator.language.substr(0, 2);
            }
            req.send(JSON.stringify(request));
        });
    },
    getBase() {
        if(window.location.host.includes('localhost') && !window.app.mobile && localStorage.getItem('forcePublicServer') !== 'true') {
            return 'http://localhost/';
        } else {
            return 'https://stadtlandfluss.cool/';
        }
    },
    encPass(pass) {
        let enc = '';
        for (let i = 0; i < pass.length; i++) {
            enc += String.fromCharCode(pass.charCodeAt(i) + i);
        }
        return enc;
    },
    decPass(pass) {
        let dec = '';
        for (let i = 0; i < pass.length; i++) {
            dec += String.fromCharCode(pass.charCodeAt(i) - i);
        }
        return dec;
    },
    getGameList() {
        const promises = [];
        for (let instance = 0; instance < this.INSTANCE_COUNT; instance++) {
            console.log("Inst", instance);
            promises.push(this.post('https://s' + instance + '.stadtlandfluss.cool/list', undefined, true));
        }
        return new Promise((resolve, reject) => {
            Promise.all(promises).then(values => {
                const list = [];
                for (const sublist of values) {
                    list.push(...sublist);
                }
                resolve(list);
            }).catch(error => {
                console.error('Could not load game list.', error);
            });
        });
    },
    applyRegistration(formData) {
        formData.password = this.encPass(formData.password);
        return this.post('server/account', {
            action: 'register',
            data: formData
        });
    },
    resetPassword(secret, pass) {
        return this.post('server/account', {
            action: 'reset-pass-confirm',
            secret,
            pass: this.encPass(pass)
        });
    },
    requestPasswordReset(mail) {
        return this.post('server/account', {
            action: 'reset-pass',
            mail
        });
    },
    confirmRegistration(confirmCode, username) {
        return this.post('server/account', {
            action: 'confirm-register',
            username,
            confirmCode
        });
    },
    setSession(session, userid) {
        this.session = session;
        this.userid = userid;
        this.app.session = session;
        if (session === undefined) {
            if (localStorage.getItem('sid')) {
                localStorage.removeItem('sid');
            }
            if (localStorage.getItem('uid')) {
                localStorage.removeItem('uid');
            }
        } else {
            localStorage.setItem('sid', session);
            localStorage.setItem('uid', userid);
        }
    },
    setProfileData(profile) {
        this.app.profileData = profile;
    },
    createSession(name, password) {
        return new Promise((resolve, reject) => {
            this.post('server/account', {
                action: 'create-session',
                name,
                password: this.encPass(password)
            }).then(response => {
                if (response.success) {
                    this.setSession(response.session, response.userid);
                }
                resolve(response);
            });
        });
    },
    requestProfileData() {
        this.profileLoading = new Promise((resolve, reject) => {
            this.post('server/account', {
                action: 'get-profile',
                session: this.session,
                userid: this.userid
            }).then(response => {
                if (response.success) {
                    this.setProfileData(response.profile);
                } else {
                    this.setSession(undefined, undefined);
                }
                resolve(response);
            });
        });
    },
    editProfile(property, value) {
        return new Promise((resolve, reject) => {
            try {
                this.post('server/account', {
                    action: 'edit',
                    session: this.session,
                    userid: this.userid,
                    property,
                    value
                }).then(response => {
                    if (response.success) {
                        this.setProfileData(response.profile);
                    }
                    resolve(response);
                });
            } catch (error) {
                console.log(error)
            }
        });
    },
    deleteAccount(pass) {
        return this.post('server/account', {
            action: 'delete',
            pass: this.encPass(pass),
            userid: this.userid,
            session: this.session
        });
    },
    addStats(points, place, answers) {
        return new Promise((resolve, reject) => {
            return this.post('server/account', {
                action: 'stats-add',
                session: this.session,
                userid: this.userid,
                points,
                place,
                answers
            }).then(response => {
                if (response.success) {
                    this.setProfileData(response.profile);
                }
                resolve(response);
            });
        });
    },
    sendContact(msg, answer, mail) {
        return this.post('server/account', {
            action: 'contact',
            msg,
            answer,
            mail
        });
    }
}

export default Client;